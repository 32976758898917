.ValEmail-OuterCont {
  display: flex;
  gap: 5%;
}

.MuiInputBase-input {
  font-size: 18px !important;
}

.ValEmail-TextField {
  width: 450px;

  margin-bottom: 40px;
}

.ValEmail-Button {
  display: flex;
  justify-content: center;
  gap: 2%;
}

thead th {
  font-size: 17px !important;
  font-weight: 600 !important;
}

tbody th {
  font-size: 15px !important;
}

.btn-display {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.w-100 {
  width: 100% !important;
}

.dragDropBox {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.smallText {
  font-weight: 300;
  font-size: 13px;
}

.mt-10 {
  margin-top: 2%;
}

.alert-danger-documentUpload {
  color: rgb(212, 47, 47);
  border-radius: 3px;
  font-size: 13px;
  text-align: center;

  padding: 5px 10px 5px 10px;
  background-color: rgb(240, 216, 216);
  margin-bottom: 2%;
}

.red {
  color: red;
}
